// import React from "react";
// import { ReactComponent as HomeImage } from "../../assets/images/navbar/home.svg";
// import { ReactComponent as EducationImage } from "../../assets/images/navbar/education.svg";
// import { ReactComponent as AboutImage } from "../../assets/images/navbar/aboutme.svg";
// import { ReactComponent as ExperienceImage } from "../../assets/images/navbar/experience.svg";
// import { ReactComponent as LearningImage } from "../../assets/images/navbar/learning.svg";
// import { ReactComponent as ProjectsImage } from "../../assets/images/navbar/projects.svg";
// import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
// import LocalLibraryRoundedIcon from "@material-ui/icons/LocalLibraryRounded";
// import WorkRoundedIcon from "@material-ui/icons/WorkRounded";
// import EmojiObjectsRoundedIcon from "@material-ui/icons/EmojiObjectsRounded";
// // import PermContactCalendarRoundedIcon from "@material-ui/icons/PermContactCalendarRounded";
// import CardMembershipIcon from "@material-ui/icons/CardMembership";
// import SchoolIcon from "@material-ui/icons/School";
// import DescriptionIcon from "@material-ui/icons/Description";

import HomeImage from "../../assets/images/navbar/home.svg";
import EducationImage from "../../assets/images/navbar/education.svg";
import AboutImage from "../../assets/images/navbar/aboutme.svg";
import ExperienceImage from "../../assets/images/navbar/experience.svg";
import LearningImage from "../../assets/images/navbar/learning.svg";
import ProjectsImage from "../../assets/images/navbar/projects.svg";

export const icons = {
	Education: EducationImage,
	About: AboutImage,
	Experience: ExperienceImage,
	Learning: LearningImage,
	Projects: ProjectsImage,
};

export const NavbarData = [
	{
		title: "Home",
		// icon: <HomeRoundedIcon />,
		icon: HomeImage,
		link: "#home",
		className: "home",
	},
	{
		title: "About Me",
		// icon: <DescriptionIcon />,
		icon: AboutImage,
		link: "#aboutMe",
		className: "aboutMe",
	},
	{
		title: "Experience",
		// icon: <WorkRoundedIcon />,
		icon: ExperienceImage,
		link: "#experience",
		className: "experience",
	},
	{
		title: "Projects",
		// icon: <EmojiObjectsRoundedIcon />,
		icon: ProjectsImage,
		link: "#projects",
		className: "projects",
	},
	// {
	// 	title: "Skills",
	// 	icon: <LocalLibraryRoundedIcon />,
	// 	link: "#skills",
	// 	className: "skills",
	// },
	{
		title: "Education",
		// icon: <SchoolIcon />,
		icon: EducationImage,
		link: "#education",
		className: "education",
	},
	{
		title: "Learnings",
		// icon: <CardMembershipIcon />,
		icon: LearningImage,
		link: "#certificates",
		className: "certificates",
	},
	// {
	// 	title: "Resume",
	// 	icon: <DescriptionIcon />,
	// 	link: "#resume",
	// 	className: "resume",
	// },
];
