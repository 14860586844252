import React, { Component, Fragment } from "react";
import "./Navbar.css";
import { Link, animateScroll as scroll } from "react-scroll";
import Drawer from "@material-ui/core/Drawer";
import { NavbarData } from "./NavbarData.js";
import { withStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CssBaseline from "@material-ui/core/CssBaseline";
import Hidden from "@material-ui/core/Hidden";
// import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";

// import myphoto from  "../../assets/images/Sateesh name.png"
// import avatar from "../../assets/images/avatar.svg";
// import profilePhoto from "../../assets/images/sateesh.png";

const drawerWidth = "260px";

const styles = (theme) => ({
	root: {
		display: "flex",
	},

	drawer: {
		[theme.breakpoints.up("md")]: {
			width: drawerWidth,
			flexShrink: 0,
			minWidth: drawerWidth,
			maxWidth: drawerWidth,
		},
	},

	toolbar: theme.mixins.toolbar,

	menuButton: {
		marginRight: theme.spacing(2),
		[theme.breakpoints.up("md")]: {
			display: "none",
		},
	},

	appBar: {
		[theme.breakpoints.up("md")]: {
			width: `calc(100% - ${drawerWidth}px)`,
			marginLeft: drawerWidth,
			minWidth: drawerWidth,
			maxWidth: drawerWidth,
		},
	},

	drawerPaper: {
		width: drawerWidth,
		background: "#000000",
		minWidth: drawerWidth,
		maxWidth: drawerWidth,
		zIndex: 0,
		// borderRight: "1px solid #222222",
		// backgroundImage: `linear-gradient(to right top, #02224E, #03285C, #032D69, #032D69, #032D69)`
	},

	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
});

class Navbar extends Component {
	constructor(props) {
		super(props);
		this.mobileOpen = React.createRef();

		this.state = {
			mobileOpen: false,
			olVisible: true,
		};
	}

	scrollToTop = () => {
		scroll.scrollToTop();
	};

	handleScroll = () => {
		const currentScrollPos = window.pageYOffset;
		if (currentScrollPos < 30) {
			this.setState({
				olVisible: false,
			});
		} else {
			this.setState({
				olVisible: true,
			});
		}
	};

	componentDidMount() {
		window.addEventListener("scroll", this.handleScroll);
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this.handleScroll);
	}

	render() {
		const { classes } = this.props;
		const { window } = this.props;

		const handleDrawerToggle = () => {
			this.setState({
				mobileOpen: !this.state.mobileOpen,
			});
		};

		const drawer = (
			<ol className="sidebarList media-body" style={{ display: "none" ? this.state.olVisible : "block" }}>
				{/* <li className="nav-photo-name">
					<div>
						{<img id="profilePhoto" className="rounded" src={profilePhoto} alt="Profile Pic" onClick={this.scrollToTop} /> }
						<h3 id="profileName" onClick={this.scrollToTop}>
							SATEESH
						</h3>
					</div>
				</li> */}
				{NavbarData.map((val, key) => {
					return (
						<Link key={key} activeClass="active" to={val.className} spy={true} smooth={true} offset={-60} duration={1200}>
							<li className="row" style={{ backgroundImage: `url(${val.icon})` }}>
								<span className="nav-title">{val.title}</span>
							</li>
						</Link>
					);
				})}
			</ol>
		);

		const container = window !== undefined ? () => window().document.body : undefined;

		// const [mobileOpen, setMobileOpen] = React.useState(false); this needs to be done inside function, for class we need to be done in constructor different ways

		return (
			<div>
				<Fragment>
					<CssBaseline />
					<nav className={classes.drawer}>
						<Hidden mdUp implementation="css">
							<AppBar position="fixed" className={classes.appBar} style={{ background: "#040b14", boxShadow: "none" }}>
								<Toolbar>
									<IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} className={classes.menuButton}>
										<MenuIcon />
									</IconButton>
								</Toolbar>
							</AppBar>
							<Drawer
								container={container}
								className={classes.drawer}
								variant="temporary"
								classes={{ paper: classes.drawerPaper }}
								anchor="left"
								open={this.state.mobileOpen}
								onClose={handleDrawerToggle}
								ModalProps={{
									keepMounted: true, // Better open performance on mobile.
								}}
							>
								{drawer}
							</Drawer>
						</Hidden>
					</nav>

					<Hidden smDown implementation="css">
						<Drawer
							classes={{
								paper: classes.drawerPaper,
							}}
							variant="permanent"
							open
						>
							{drawer}
						</Drawer>
					</Hidden>
				</Fragment>
			</div>
		);
	}
}

export default withStyles(styles)(Navbar);

// function Sidebar() {
//     return (
//         <div className="sidebar">

//             <ul className="sidebarList">
//                 <li className="nav-photo">
//                     <div align="center">
//                         <img id="profilePhoto" src={avatar} alt="Profile Pic"/><br/>
//                         <a id="profileName" href="/home"><h3>Sateesh Yavarna</h3><span>Data Analyst</span></a>
//                     </div>
//                 </li>
//                 {

//                     SidebarData.map((val,key) => {
//                         return(
//                             <li key={key} className="row" id={window.location.pathname === val.link ? "active" : ""} onClick={() => {window.location.pathname = val.link}}>
//                                 <div id="nav-icon">{val.icon}</div>
//                                 <div id="nav-title">{val.title}</div>
//                             </li>
//                         )
//                     })
//                 }
//             </ul>
//         </div>
//     )
// }

// export default Sidebar
