import "./App.css";
// import Sidebar from "./Components/SideBar/Sidebar";
import Navbar from "./Components/Navbar/Navbar";
import Home from "./Pages/Home/Home.js";
import Education from "./Pages/Education/Education.js";
import Experience from "./Pages/Experience/Experience.js";
import Projects from "./Pages/Projects/Projects.js";
// import Skills from "./Pages/Skills/Skills.js";
import AboutMe from "./Pages/AboutMe/AboutMe.js";
import Certificate from "./Pages/Certificates/Certificates.js";

import { BrowserRouter as Router } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

// import { makeStyles } from "@material-ui/core/styles";
import React, { Component } from "react";
// import COLORS from "./assets/constants.js";

const styles = (theme) => ({
	root: {
		display: "flex",
	},
});

class App extends Component {
	render() {
		const { classes } = this.props;
		return (
			<Router>
				<div className={classes.root}>
					{/* <Sidebar /> */}
					<Navbar />

					<main className="content">
						<Home />
						<AboutMe />
						<Experience />
						<Projects />
						{/* <Skills /> */}
						<Education />
						<Certificate />
					</main>
				</div>
			</Router>
		);
	}
}

export default withStyles(styles)(App);
