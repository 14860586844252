import React from "react";
import "./Projects.css";
// import sampleImage from "../../assets/images/sampleImage.jpg";
import ProjectData from "./projectData.js";
// import { icons } from "../../Components/Navbar/NavbarData.js";

// import { Accordion,Card,Button } from 'react-bootstrap';

function Home() {
	return (
		<div id="projects" className="container-fluid">
			<div className="row justify-content-md-center">
				<div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
					<h1>Projects</h1>
					<div className="headerUnderline"></div>
					<div className="row">
						<div className="projectsMenu col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
							<ul className="nav flex-column nav-pills" role="tablist" aria-orientation="vertical">
								{ProjectData.map((val, key) => {
									return (
										<li key={key} className="nav-item p-1 projectLink">
											<a className={"nav-link " + (val.active ? "active" : "")} href={"#" + val.id} data-toggle="pill" role="tab">
												{val.tabTitle}
											</a>
										</li>
									);
								})}
							</ul>
						</div>
						<div className="projectsContent col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 ">
							<div className="tab-content height">
								{ProjectData.map((val, key) => {
									return (
										<div key={key} className={"tab-pane fade  " + (val.active ? "show active" : "")} id={val.id}>
											<div className="container">
												<div className="row">
													{val.projects.map((projectVal, key) => {
														return (
															<div key={key} className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-2">
																<div className="project">
																	<div className="card">
																		<div className="row">
																			<div className="col-md-12">
																				<div className="card-body">
																					<h5 className="card-title">{projectVal.name}</h5>
																					<p className="card-text">{projectVal.shortDesc}</p>
																				</div>
																			</div>
																			<div className="project-overlay"></div>
																		</div>
																	</div>
																	<div className="project-details fadeIn-bottom">
																		<button className="btn btn-dark mask-btn" type="button" data-toggle="modal" data-target={"#" + val.id + "-" + projectVal.id}>
																			View More
																		</button>
																	</div>
																</div>
																<div className="modal fade popup" id={val.id + "-" + projectVal.id} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
																	<div className="modal-dialog modal-dialog modal-lg popup-inner" role="document">
																		<div className="modal-content">
																			<div className="modal-header">
																				<div className="col-11">
																					<h3 className="heading text-center">{projectVal.name}</h3>
																				</div>
																				<button className=" btn close" data-dismiss="modal" aria-label="Close">
																					<a className="popup-close" href="!#" aria-hidden="true">
																						{""}
																					</a>
																				</button>
																			</div>
																			<div className="modal-body">
																				<div className="row">
																					<div className="col-12">
																						<div className="row">
																							{/* <div className="prImage col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
																								<img id="projectImage" className="mr-3" src={projectVal.image} alt={projectVal.name} />
																							</div> */}
																							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-11 col-xl-11 offset-md-1 offset-sm-1 offset-lg-1 offset-xl-1 project-content">
																								<div className="client-info">
																									<ul className="list-unstyled">
																										{projectVal.projectMeta.map((projectMeta, key) => {
																											return (
																												<li key={key} className="mb-2">
																													<i className={projectMeta.icon + " mr-2 "}></i>
																													<strong>{projectMeta.name}</strong> {projectMeta.value}
																												</li>
																											);
																										})}
																									</ul>
																								</div>
																							</div>
																						</div>
																						<div className="row">
																							<div className="col-12 mt-3">
																								<div className="client-bio mb-4">{projectVal.objective}</div>
																								<h4 className="subheading mb-3">My work towards the project</h4>
																								<ul className="mb-0">
																									{projectVal.contribution.map((pDesc, key) => {
																										return (
																											<li key={key} className="mb-2">
																												{pDesc}
																											</li>
																										);
																									})}
																								</ul>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														);
													})}
												</div>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Home;
