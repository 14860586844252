import React from "react";
// import Container from '@material-ui/core/Container';
// import Box from '@material-ui/core/Box';
// import {Typography} from '@material-ui/core';
import "./Education.css";
import { educationData, achievementsData } from "./EducationData.js";
// import { icons } from "../../Components/Navbar/NavbarData.js";
// import "./Education.scss";

// import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

function Home() {
	return (
		<div id="education_achievement" className="container-fluid">
			<div id="education" className="row justify-content-md-center">
				<div className="col-xs-12 col-sm-12 col-md-12 col-lg-10 col-xl-10">
					<h1>Education</h1>
					<div className="headerUnderline"></div>
					{/* <h3> Years Education!</h3>
                <p>Quis nostrud ullamco nulla officia irure reprehenderit officia duis irure consequat id reprehenderit consequat. Irure nulla pariatur et cillum sunt commodo eu enim anim consectetur irure nisi. Minim nulla nisi ut consequat. Dolor amet Lorem officia do incididunt culpa aliqua et irure anim enim sit consequat ea. Nulla et ad ullamco consectetur eiusmod nostrud eu magna est proident quis cillum id sint. Incididunt fugiat labore qui labore. Deserunt ullamco et pariatur cupidatat aliquip irure enim laboris laboris.</p> */}
					<div className="educationmainContainer">
						{educationData.map((val, key) => {
							return (
								<div key={key}>
									<div className="contentDivider"></div>
									<div className="education-details">
										<div className="row margin-top30 margin-bottom30">
											<div className="justify-content-center align-items-center col-lg-6 col-md-12 col-sm-12 col-xl-6 media">
												<h5>
													<i className="fa fa-graduation-cap mr-3"></i>
												</h5>
												<div className="media-body">
													<h4>{val.course}</h4>
												</div>
											</div>

											<div className="col-lg-6 col-md-12 col-sm-12 col-xl-6 ">
												<div className="media">
													<h5>
														<i className="fa fa-university mr-3 "></i>
													</h5>
													<h4>
														{val.college}, {val.University}
													</h4>
												</div>
												<div className="row detailsRow">
													<div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 media">
														<h5>
															<i className="fa fa-map-marker mr-3"></i>
														</h5>
														<div className="media-body">
															<h5>{val.location}</h5>
														</div>
													</div>
													<div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 media">
														<h5>
															<i className="fa fa-calendar mr-3"></i>
														</h5>
														<div className="media-body">
															<h5>{val.timespan}</h5>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
			<div id="achievements">
				<div className="row justify-content-md-center">
					<div className="col-xs-12 col-sm-12 col-md-10 col-lg-10  col-xl-10">
						<h2>Academic Achievements</h2>
						<div className="headerUnderline"></div>
						{/* <h3> Years Education!</h3>
                <p>Quis nostrud ullamco nulla officia irure reprehenderit officia duis irure consequat id reprehenderit consequat. Irure nulla pariatur et cillum sunt commodo eu enim anim consectetur irure nisi. Minim nulla nisi ut consequat. Dolor amet Lorem officia do incididunt culpa aliqua et irure anim enim sit consequat ea. Nulla et ad ullamco consectetur eiusmod nostrud eu magna est proident quis cillum id sint. Incididunt fugiat labore qui labore. Deserunt ullamco et pariatur cupidatat aliquip irure enim laboris laboris.</p> */}
						<div className="achievementsmainContainer">
							{achievementsData.map((val, key) => {
								return (
									<div key={key}>
										<div className="contentDivider"></div>
										<div className="achievementsdetails">
											<div className="row margin-top30 margin-bottom30">
												<div className="justify-content-center align-items-center col-lg-6 col-md-6 col-sm-6 col-xl-6 media">
													<h5>
														<i className="fas fa-award mr-3"></i>
													</h5>
													<div className="media-body">
														<h4>{val.name}</h4>
													</div>
												</div>
												<div className="col-lg-6 col-md-6 col-sm-6 col-xl-6">
													<div className="container">
														{/* <div className="row">
															<div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">
																<h5>
																	<i className="fa fa-graduation-cap mr-3"></i>
																</h5>
															</div>
															<div className="col-xs-11 col-sm-11 col-md-11 col-lg-11 col-xl-11">
																<h5>{val.course}</h5>
															</div>
														</div> */}
														<div className="row">
															<div>
																<h5>{val.desc}</h5>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Home;
