// export const experience = 5;

// export const headline =
//   "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ornarecsem sed quam tempus aliquet vitae eget dolor. Proin eu ultrices libero. Curabitur vulputate vestibulum elementum. Suspendisse id neque a nibh mollis blandit. Quisque varius eros ac purus dignissim.";

// import dcLogo from "../../assets/images/education/datacamp_logo.svg";

export const educationData = [
	{
		college: "Centre for Distance Learning",
		collegeLogo: "",
		University: "Gitam University",
		url: "",
		location: "Vishakaptnam",
		course: "Master of Business Administration - HRM",
		timespan: "Aug 17 - Sept 19",
	},
	{
		college: "School of Information Technology",
		collegeLogo: "",
		University: "Jawaharlal Nehru Technological University",
		url: "",
		location: "Hyderabad",
		course: "Master of computer Applications",
		timespan: "Sept 13 - Mar 16",
	},
];

export const achievementsData = [
	{
		name: "Sir Sudir C Rao MDF Gold Medal",
		course: "Master of Computer Applications",
		desc: "Achieved for the overall topper in Master of Computer Applications at School of Information Technology (SIT), Jawaharlal Nehru Technological University.",
	},
];
