import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@material-ui/core/Button";
import { resumePdf, heyImage, homePersonImage, name, description, socialIcons } from "./HomeData.js";
import "./Home.css";

function Home() {
	return (
		<div className="home container-fluid" key="home" id="home">
			<div className="row justify-content-md-center">
				<div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 ">
					<div className="homeContent ">
						<div className="row align-items-center">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 welcomeImageBlock">
								<img className="welcomeImage" alt="Home Page" src={homePersonImage} />
							</div>
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 heyBlock">
								<div className="row">
									<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
										<img className="heyImage" alt="Waiving hand" src={heyImage} />
										<span className="wave">👋</span>
									</div>
								</div>
								<div className="row">
									<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
										<div className="row">
											<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
												<p className="name">{name}</p>
											</div>
										</div>
										<div className="row">
											<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
												<span>{description}</span>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
										<div className="social-media-div">
											{socialIcons.map((val, key) => (
												<a href={val.url} key={key} target="_blank" rel="noreferrer" className={"icon-button " + val.name}>
													<span style={{ backgroundColor: val.backgroundColor }}>
														<FontAwesomeIcon className="icon" icon={val.icon} />
													</span>
												</a>
											))}
										</div>
									</div>
								</div>
								<div className="row resumeBtnBlock">
									<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
										<a href={resumePdf} className="resumeLink" target="_blank" rel="noopener noreferrer">
											<Button className="resumeBtn" variant="contained">
												See My Resume
											</Button>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <div className="modal fade popup" id="resumeModal" role="dialog" aria-labelledby="resumeModalCenter" aria-hidden="true">
				<div className="modal-dialog modal-dialog modal-xl popup-inner" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<div className="col-11">
								<h3 className="heading text-center">Resume</h3>
							</div>
							<button className="btn close" data-dismiss="modal" aria-label="Close">
								<a className="popup-close" href="!#">
									{" "}
								</a>
							</button>
						</div>
						<div className="modal-body">
							<SinglePagePDFViewer pdf={resumePdf} renderMode="svg" />
						</div>
					</div>
				</div>
			</div> */}
		</div >
	);
}

export default Home;
