import React from "react";
import "../AboutMe/AboutMe.css";
import { photo, role, description, bio, skillsIcons, toolsIcon } from "./AboutMeData.js";
import Tooltip from "@material-ui/core/Tooltip";
// import { icons } from "../../Components/Navbar/NavbarData.js";

function AboutMe() {
	return (
		<div id="aboutMe" className="container-fluid">
			<div className="row justify-content-center">
				<div className="col-sm-12 col-xs-12 col-md-10 col-lg-10 col-xl-10">
					<span>
						<h1>About Me</h1>
					</span>
					<div className="headerUnderline"></div>
					<div className="aboutMemainContainer">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 imageBlock">
								<img src={photo} alt="My Pic" className="myphoto" />
							</div>

							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 descBlock">
								<span className="heading">
									<div className="row">
										<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
											<h4>
												I'm a <span id="role-name">{role}</span>
											</h4>
										</div>
									</div>
								</span>
								<div className="row">
									<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
										<p>{description}</p>
									</div>
								</div>
								<div className="row textFont">
									<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
										<div className="row">
											<div className="col-xs-12 col-sm-12 col-md-6 col-lg-12 col-xl-12 d-flex bio">
												<ol className="mybio-list">
													{bio.map((val, key) => {
														return (
															<li key={key} className="iconImage" style={{ backgroundImage: `url(${val.image})` }}>
																<span className="title">{val.title}</span>
																<span className="value">{val.value}</span>
															</li>
														);
													})}
												</ol>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div>
						<div id="skills" className="row">
							<div className="container-fluid">
								<div className="col-xs-12 col-md-12 col-lg-12 col-sm-12 col-xl-12">
									<div className="skillsmainContainer">
										<h3 className="skillHeading">
											What <span className="highlightText">I know</span>
										</h3>
										<div className="row skillBlock">
											{skillsIcons.map((val1, key1) => {
												return (
													<div key={key1} className="skillIcons col-md-1 col-2">
														<Tooltip title={val1.name} arrow placement="top">
															<img className="skillImage" alt={val1.name} src={val1.image} />
														</Tooltip>
														<h4 className="skillName">{val1.name}</h4>
													</div>
												);
											})}
										</div>
										<div>
											<h3 className="skillHeading">
												What <span className="highlightText">I Use</span>
											</h3>
										</div>
										<div className="row skillBlock">
											{toolsIcon.map((val1, key1) => {
												return (
													<div key={key1} className="skillIcons col-md-1 col-2">
														<Tooltip title={val1.name} arrow placement="top">
															<img className="skillImage" alt={val1.name} src={val1.image} />
														</Tooltip>
														<h4 className="skillName">{val1.name}</h4>
													</div>
												);
											})}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AboutMe;
