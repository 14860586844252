import resumePDF from "../../assets/docs/resume.pdf";
import homePerson from "../../assets/images/home/man_waiving_hand.svg";
import heyImag from "../../assets/images/home/hey1.svg";

var startDate = new Date("11/21/2016");
var today = new Date();
var milliDay = 1000 * 60 * 60 * 24; // a day in milliseconds;
var age = (today - startDate) / milliDay / 365;

export const experience = age.toFixed(1);

export const resumePdf = resumePDF;
export const heyImage = heyImag;
export const homePersonImage = homePerson;

export const name = "I'm Sateesh";
export const description = "Welcome to my Devfolio.\n I'm honing my skills in statistical analysis, machine learning, and data visualization. On this website, you will find my work, resume and whereabouts. I am constantly seeking new challenges and opportunities to push my skills and creativity to the next level. I hope my Devfolio gives you a sense of my style and capabilities. Thank you for visiting!";
export const socialIcons = [
	{
		name: "github",
		icon: ["fab", "github"],
		url: "https://github.com/sateeshyavarna",
		backgroundColor: "#333333",
	},
	{
		name: "linkedin",
		icon: ["fab", "linkedin"],
		url: "https://www.linkedin.com/in/sateeshyavarna/",
		backgroundColor: "#0e76a8",
	},
	{
		name: "mail",
		icon: ["fas", "envelope"],
		url: "mailto:yavarnasateesh@gmail.com",
		backgroundColor: "#ea4335",
	},
];
