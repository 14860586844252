import fiImage from "../../assets/images/projects/fiImage.png";
import loanImage from "../../assets/images/projects/loanImage.png";
import distressImage from "../../assets/images/projects/distressImage.jpg";

import sampleImage from "../../assets/images/sampleImage.jpg";
const projectsData = [
	{
		company: "Quantium Analytics",
		tabTitle: "Quantium Analytics",
		url: "qnt",
		id: "qntProject",
		active: true,
		projects: [
			{
				id: 1,
				image: sampleImage,
				name: "Transaction Enrichment & attribute generation",
				shortDesc: "Financial product which is designed to transform raw transaction data into actionable insights, enabling data-driven decision making, and providing accurate lending quality assessments. ",
				contribution: ["Incorporating automation and process enhancements to the exisiting workflow", "Automated report generation which help to support true potential of the product", "Improving the quality of the product by utilizing my analytical skills, data analysis expertise and by using the results of the product."],
				objective: "",
				projectMeta: [
					{
						icon: "fa fa-industry",
						name: "Industry",
						value: "Banking & Finance",
					},
					{
						icon: "fa fa-industry",
						name: "Role",
						value: "Data Scientist",
					},
					{
						icon: "fa fa-industry",
						name: "Technologies",
						value: ["PySpark", "Scala", "Python", "Natural Language Processing (NLP)"].toString(),
					},
					{
						icon: "fa fa-industry",
						name: "Duration",
						value: "Jun 2020 - Till Date",
					},
					{
						icon: "fa fa-industry",
						name: "Team Size",
						value: "6",
					},
				],
			},
		],
	},
	{
		company: "LatentView Analytics",
		tabTitle: "LatentView Analytics",
		url: "lv",
		id: "lvProject",
		active: false,
		projects: [
			{
				id: 1,
				image: sampleImage,
				name: "Early Quality issues Detection in assembly line",
				shortDesc: "Detect unit concern spikes for early quality issues and support the quality activities of plant vehicle team in a plant",
				contribution: ["Design and Developed pipelines for data extraction from different sources in Alteryx", "Analysing the data and root cause analysis and unit concerns after collecting the data", "Created a qlikview dashboard to visualise the analysis in pictorial presentation"],
				objective: "Quality Leadership System of Vehicle Operation(QLSVO) is an electronic system used to manage vehicle qualiy data with assembly plants. It stores inspection data, vehicle audit data and repair data. By examining and analysing the data, such as back-tracking and root cause analysis will improve the manudacturing process.",
				projectMeta: [
					{
						icon: "fa fa-industry",
						name: "Industry : ",
						value: "Automotive Industry",
					},
					{
						icon: "fa fa-user",
						name: "Role : ",
						value: "Data Analyst",
					},
					{
						icon: "fa fa-info-circle",
						name: "Technologies : ",
						value: ["Alteryx", "QlikView", "Python"].join(", "),
					},
					{
						icon: "fa fa-clock",
						name: "Duration : ",
						value: "Jan 2021 - May 2021",
					},
					{
						icon: "fa fa-users",
						name: "Team Size : ",
						value: "1",
					},
				],
			},
			{
				id: 2,
				image: sampleImage,
				name: "Field Service Actions",
				shortDesc: "Analyzing real time Recalls Data to understand the areas to focus, to continuously improve the Quality​",
				contribution: ["Involved in and Responsible for end to end project documentation from Kick Off Meeting to User Acceptance Testing & Project Delivery", "Redesigned the entire application architechture to improve the performance of the application ", "Implemented automated workflows using Alteryx which will collect the data coming from different sources", "Qlikview dashboard is designed and implemented from the data which is used to review and understand recalls to improve the quality in realtime"],
				objective: "Field Service action project primarily focusses on utilising NHTSA Recall data to understand different vehicle's data across USA to figure out the frequent components affected with respect to models or make and to benchmark recalls against competitors",
				projectMeta: [
					{
						icon: "fa fa-industry",
						name: "Industry : ",
						value: "Automotive Industry",
					},
					{
						icon: "fa fa-user",
						name: "Role : ",
						value: "Data Analyst",
					},
					{
						icon: "fa fa-info-circle",
						name: "Technologies : ",
						value: ["Alteryx", "Python", "QlikView"].join(", "),
					},
					{
						icon: "fa fa-clock",
						name: "Duration : ",
						value: "Dec 2019 - Sep 2020",
					},
					{
						icon: "fa fa-users",
						name: "Team Size : ",
						value: "1",
					},
				],
			},
			{
				id: 3,
				image: sampleImage,
				name: "Real time dealer performance anomalies",
				shortDesc: "Analyzing real time vehicle data and service order data in order to find out the anomalies present in automotive dealers ​",
				objective: "In an automotive Industry piles of data is being created each single day in terms of vehicle data, dealer data and servcie reports. Primary focus of application is on identifying dealer performance in terms of servicing and detecting anomalies like false reports made by dealer using Connected vehicle data",
				contribution: ["Involved in Clients Requirements Gathering and Document Maintenance for the entire application.", "Developed alteryx workflow to integrate connceted vehicle data, service reports data and dealer data", "Identified key performance indicators(KPI) which will be used to assess the dealer performance", " Desinged and developed Qlikview dashboard to visualise the KPI and dealer anamaly information."],
				projectMeta: [
					{
						icon: "fa fa-industry",
						name: "Industry : ",
						value: "Automotive Industry",
					},
					{
						icon: "fa fa-user",
						name: "Role : ",
						value: "Data Analyst",
					},
					{
						icon: "fa fa-info-circle",
						name: "Technologies : ",
						value: ["Alteryx", "Python", "QlikView"].join(", "),
					},
					{
						icon: "fa fa-clock",
						name: "Duration : ",
						value: "Sep 2020 - Jan 2021",
					},
					{
						icon: "fa fa-users",
						name: "Team Size : ",
						value: "1",
					},
				],
			},
			{
				id: 4,
				image: sampleImage,
				name: "Prediction of Class Action Law suits - POC",
				shortDesc: "Alerting companies with potential issues by predicting class action lawsuits which are quite expensive and cause reputation loss for automobile company",
				objective: "In an automotive Industry Class action lawsuits allow hundreds or potentially thousands of individuals to join and take legal action against a person or entity in instances when it would not be financially viable for them to file individual lawsuits.​ A class action automotive lawsuit might be an option anytime a design or manufacturing defect or other problem with a vehicle affects a group of people. This project focus on identifying potential class action lawsuits before hand to esacpe from huge losses for the company",
				contribution: ["Involved in Web scraping the data from social media websites and Complaints data from NHTSA website ", "Applied Natural language processing to clean, summarise and to create ngrams from the complaints", "Identified key metrics and patterns to predict the conversion of litigation to a class action", "Visualised the analysis of the data using QlikView"],
				projectMeta: [
					{
						icon: "fa fa-industry",
						name: "Industry : ",
						value: "Automotive Industry",
					},
					{
						icon: "fa fa-user",
						name: "Role : ",
						value: "Data Analyst",
					},
					{
						icon: "fa fa-info-circle",
						name: "Technologies : ",
						value: ["Natural Language Processing", "Python", "Web Scraping", "QlikView", ""].join(", "),
					},
					{
						icon: "fa fa-clock",
						name: "Duration : ",
						value: "Jun 2020 - Oct 2021",
					},
					{
						icon: "fa fa-users",
						name: "Team Size : ",
						value: "1",
					},
				],
			},
			{
				id: 5,
				image: sampleImage,
				name: "MEK Dewaxing filter wash system - POC",
				shortDesc: "Applied Predictive modelling to predict filter to be washed with hot solvent in Oil Dewaxing process in Oil refinery",
				objective: "Client has an Oil Refinery Industry and it contains a MEK unit which uses a two stage filter system to separate the oil and wax. Over time the filter cloths will be coated with wax (fouling) and needs to be taken out of service and washed with hot solvent to remove the wax cake formation. Objective is to find the optimum time to bring filters of 1st stage offline in MEK Dewaxing filtering unit using analytics techniques in order to utilize it to it’s maximum efficiency​",
				contribution: ["Supported in building Simulator which predicts the feed rate using regression model", "Developed a optimizer which will suggest filter to be cleaned based on the simulator"],
				projectMeta: [
					{
						icon: "fa fa-industry",
						name: "Industry : ",
						value: "Oil Refinery",
					},
					{
						icon: "fa fa-user",
						name: "Role : ",
						value: "Data Analyst",
					},
					{
						icon: "fa fa-info-circle",
						name: "Technologies : ",
						value: ["Machine Learning", "Python"].join(", "),
					},
					{
						icon: "fa fa-clock",
						name: "Duration : ",
						value: "Oct 2019 - Dec 2019",
					},
					{
						icon: "fa fa-users",
						name: "Team Size : ",
						value: "2",
					},
				],
			},
		],
	},
	{
		company: "Tata Consultancy Service",
		tabTitle: "Tata Consultancy Service",
		url: "tcs",
		id: "tcsProject",
		active: false,
		projects: [
			{
				id: 1,
				image: fiImage,
				name: "Financial Inclusion for Banks",
				shortDesc: "Restructuring the FI Gateway System and Development of Financial and Non Financial transactions for Tablet devices",
				objective: "Financial Inclusion is a payment process for ensuring access to financial services and timely and adequate credit where needed by weaker sections and low income groups at an affordable cost. Financial Inclusion provides support for both Financial(Deposit, Withdrawl and Fund transfer) and Non Financial transactions(Balance Enquiry, Ministatement and Account Summary).",
				contribution: [" Redesigned and developed the communciation channel between FI Gateway and NPCI server which in turn added stability and reduced the request processing time", "Worked along with teams in requirement gathering and gateway architecture for building the communication channel", "Designed and Developed the transaction channel between the Tablet Devices and FI Server which inturn fastened the transaction and minimalised the data flow.", "Development of Bill Payment System to the POS and tablet devices and Auto Response Simulators for handling payment systems", "Evaluated and solved trouble tickets by coordinating with UAT and with Clients and thereby reduced financial loss tot the clients."],
				projectMeta: [
					{
						icon: "fa fa-industry",
						name: "Industry : ",
						value: "Banking & Finance",
					},
					{
						icon: "fa fa-user",
						name: "Role : ",
						value: "Assistant Systems Engineer - Trainee",
					},
					{
						icon: "fa fa-info-circle",
						name: "Technologies : ",
						value: ["Java", "Struts", "Hibernate", "JSP", "Servlets", "MySQL"].join(", "),
					},
					{
						icon: "fa fa-clock",
						name: "Duration : ",
						value: "Nov 2016 - Dec 2017",
					},
					{
						icon: "fa fa-users",
						name: "Team Size : ",
						value: "4",
					},
				],
			},
			{
				id: 2,
				image: distressImage,
				name: "Distress Identification and Ranking System",
				shortDesc: "Analysis on Distress of Aircraft Engine Components and Ranking the image based on distress ranking scale",
				objective: "Distress Identification is one of the crucial process in aircraft component management which enables companies to escape from huge losses. Manual process of the distress identification and analysis is a tedious process and time taking. Project's objective to automate the identifying the worst location of the distress and visualise the analysis by different metrics.",
				contribution: ["Collecting, Pre-processing, cleansing the data used for analysis.", "Building Dash Board to provide insights of data collected from various source.", "Applied exploratory data analysis on the data collected.", "Highlighting the distress on the image for processing.", "Ranking and marking the image data based on the Scale provided."],
				projectMeta: [
					{
						icon: "fa fa-industry",
						name: "Industry : ",
						value: "Aviation",
					},
					{
						icon: "fa fa-user",
						name: "Role : ",
						value: "Data Analyst",
					},
					{
						icon: "fa fa-info-circle",
						name: "Technologies : ",
						value: ["Python", "Dash", "Open CV"].join(", "),
					},
					{
						icon: "fa fa-clock",
						name: "Duration : ",
						value: "Sep 2018 - Sep 2019",
					},
					{
						icon: "fa fa-users",
						name: "Team Size : ",
						value: "1",
					},
				],
			},
			{
				id: 3,
				image: loanImage,
				name: "Automation of Loan Approval Process - POC",
				shortDesc: "Automation of loan approval eligibility process based on the customer detail provided while filling online application form",
				objective: "In finance, a loan is the lending of money by one or more individuals, organizations, or other entities to other individuals, organizations, etc. The recipient (i.e. the borrower) incurs a debt, and is usually liable to pay interest on that debt until it is repaid, and also to repay the principal amount borrowed. The whole process of ascertaining if a burrower would pay back loans might be tedious hence the need to automate the procedure. The major aim of this project is to predict the customers will have their loan paid or not.",
				contribution: ["Responsible for accessing and importing the data into python environment from SQL database server.", "Analysed the data by data visualisation package SEABORN & MATPLOTLIB in the python.", "Applied feature engineering, data preprocessing techniques by using PANDAS & NUMPY package in python before building a machine learning model. ", "Building a machine learning model on a training data set by using SCIKIT LEARN package in python and Evaluated various performance metrics on results of the model"],
				projectMeta: [
					{
						icon: "fa fa-industry",
						name: "Industry : ",
						value: "Banking & Finance",
					},
					{
						icon: "fa fa-user",
						name: "Role : ",
						value: "Assistant Systems Engineer",
					},
					{
						icon: "fa fa-info-circle",
						name: "Technologies : ",
						value: ["Machine Learning", "Python", "MySQL"].join(", "),
					},
					{
						icon: "fa fa-clock",
						name: "Duration : ",
						value: "Nov 2017 - Aug 2018",
					},
					{
						icon: "fa fa-users",
						name: "Team Size : ",
						value: "1",
					},
				],
			},
		],
	},
	// {
	// 	company: "Personal Projects",
	// 	tabTitle: "Personal Projects",
	// 	url: "personal",
	// 	id: "personalProject",
	// 	active: false,
	// 	projects: [
	// 		{
	// 			id: 1,
	// 			image:sampleImage,
	// 			name: "Financial Inclusion for Nationalized Banks",
	// 			shortDesc: "Consequat pariatur duis ipsum pariatur aliqua cupidatat voluptate eu culpa incididunt. Fugiat nisi ad quis est",
	// 			contribution: ["Consequat pariatur duis ipsum pariatur aliqua cupidatat voluptate eu culpa incididunt", "Requirement lorem ipsum dolor sit amet, consectetuer adipiscing elit.", "Requirement donec pede justo, fringilla vel, aliquet nec", "Requirement phasellus ullamcorper ipsum rutrum nunc."],
	// 			objective: "Consequat pariatur duis ipsum pariatur aliqua cupidatat voluptate eu culpa incididunt. Fugiat nisi ad quis est proident enim laborum pariatur enim est minim. Laboris amet id sit aliqua ea qui velit occaecat voluptate Lorem incididunt fugiat nulla occaecat. Aliquip velit anim cupidatat cupidatat est tempor nostrud dolor. Aliquip duis exercitation ipsum qui excepteur labore nostrud non. Irure aute culpa deserunt sunt proident aute cillum dolor non consequat sunt reprehenderit.",
	// 			projectMeta: [
	// 				{
	// 					icon: "fa fa-industry",
	// 					name: "Industry",
	// 					value: "Banking & Finance",
	// 				},
	// 				{
	// 					icon: "fa fa-industry",
	// 					name: "Role",
	// 					value: "Assistant Systems Engineer",
	// 				},
	// 				{
	// 					icon: "fa fa-industry",
	// 					name: "Technologies",
	// 					value: ["Java", "Struts", "Hibernate", "JSP", "Servlets", "MySQL"].toString(),
	// 				},
	// 				{
	// 					icon: "fa fa-industry",
	// 					name: "Duration",
	// 					value: "Nov 2016 - Dec 2017",
	// 				},
	// 				{
	// 					icon: "fa fa-industry",
	// 					name: "Team Size",
	// 					value: "4",
	// 				},
	// 			],
	// 		},
	// 		{
	// 			id: 2,
	// 			image:sampleImage,
	// 			name: "Financial Inclusion for Nationalized Banks - 2nd One",
	// 			shortDesc: "Consequat pariatur duis ipsum pariatur aliqua cupidatat voluptate eu culpa incididunt. Fugiat nisi ad quis est",
	// 			contribution: ["Consequat pariatur duis ipsum pariatur aliqua cupidatat voluptate eu culpa incididunt", "Requirement lorem ipsum dolor sit amet, consectetuer adipiscing elit.", "Requirement donec pede justo, fringilla vel, aliquet nec", "Requirement phasellus ullamcorper ipsum rutrum nunc."],
	// 			objective: "Consequat pariatur duis ipsum pariatur aliqua cupidatat voluptate eu culpa incididunt. Fugiat nisi ad quis est proident enim laborum pariatur enim est minim. Laboris amet id sit aliqua ea qui velit occaecat voluptate Lorem incididunt fugiat nulla occaecat. Aliquip velit anim cupidatat cupidatat est tempor nostrud dolor. Aliquip duis exercitation ipsum qui excepteur labore nostrud non. Irure aute culpa deserunt sunt proident aute cillum dolor non consequat sunt reprehenderit.",
	// 			projectMeta: [
	// 				{
	// 					icon: "fa fa-industry",
	// 					name: "Industry",
	// 					value: "Banking & Finance",
	// 				},
	// 				{
	// 					icon: "fa fa-industry",
	// 					name: "Role",
	// 					value: "Assistant Systems Engineer",
	// 				},
	// 				{
	// 					icon: "fa fa-industry",
	// 					name: "Technologies",
	// 					value: ["Java", "Struts", "Hibernate", "JSP", "Servlets", "MySQL"].toString(),
	// 				},
	// 				{
	// 					icon: "fa fa-industry",
	// 					name: "Duration",
	// 					value: "Nov 2016 - Dec 2017",
	// 				},
	// 				{
	// 					icon: "fa fa-industry",
	// 					name: "Team Size",
	// 					value: "4",
	// 				},
	// 			],
	// 		},
	// 		{
	// 			id: 3,
	// 			image:sampleImage,
	// 			name: "Financial Inclusion for Nationalized Banks - 3rd One",
	// 			shortDesc: "Consequat pariatur duis ipsum pariatur aliqua cupidatat voluptate eu culpa incididunt. Fugiat nisi ad quis est",
	// 			contribution: ["Consequat pariatur duis ipsum pariatur aliqua cupidatat voluptate eu culpa incididunt", "Requirement lorem ipsum dolor sit amet, consectetuer adipiscing elit.", "Requirement donec pede justo, fringilla vel, aliquet nec", "Requirement phasellus ullamcorper ipsum rutrum nunc."],
	// 			objective: "Consequat pariatur duis ipsum pariatur aliqua cupidatat voluptate eu culpa incididunt. Fugiat nisi ad quis est proident enim laborum pariatur enim est minim. Laboris amet id sit aliqua ea qui velit occaecat voluptate Lorem incididunt fugiat nulla occaecat. Aliquip velit anim cupidatat cupidatat est tempor nostrud dolor. Aliquip duis exercitation ipsum qui excepteur labore nostrud non. Irure aute culpa deserunt sunt proident aute cillum dolor non consequat sunt reprehenderit.",
	// 			projectMeta: [
	// 				{
	// 					icon: "fa fa-industry",
	// 					name: "Industry",
	// 					value: "Banking & Finance",
	// 				},
	// 				{
	// 					icon: "fa fa-industry",
	// 					name: "Role",
	// 					value: "Assistant Systems Engineer",
	// 				},
	// 				{
	// 					icon: "fa fa-industry",
	// 					name: "Technologies",
	// 					value: ["Java", "Struts", "Hibernate", "JSP", "Servlets", "MySQL"].toString(),
	// 				},
	// 				{
	// 					icon: "fa fa-industry",
	// 					name: "Duration",
	// 					value: "Nov 2016 - Dec 2017",
	// 				},
	// 				{
	// 					icon: "fa fa-industry",
	// 					name: "Team Size",
	// 					value: "4",
	// 				},
	// 			],
	// 		},
	// 	],
	// },
];
export default projectsData;
